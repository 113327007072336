import { defineStore } from 'pinia';
export const useInitStore = defineStore('init', {
    state: () => ({
        user: false,
    }),
    getters: {
        isLoggedIn: (state) => true,
        //isLoggedIn: (state) => state.user != null,
    },
});
