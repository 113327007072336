import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
export const useCalendarTemplatesStore = defineStore('calendarTemplates', () => {
    const templates = ref([
        {
            name: 'basic_photo',
            title: 'Basic Calendar',
            img: '/images/basic-template.png',
            path: '/',
            premium: false,
        },
        {
            name: 'watercolor_photo',
            title: 'Watercolor Calendar',
            img: '/images/watercolor-calendar-thumb2.png',
            path: '/template/watercolor',
            premium: false,
            settings: {
                shapeColor: 'blueviolet',
                shapeSize: 50,
                shapeFile: 'watercolor-7.png',
            }
        },
        {
            name: 'premium_photo2',
            title: 'Photo Calendar',
            img: '/images/premium-calendar2-thumb.png',
            path: '/template/premium_template',
            premium: false,
            settings: {
                headerBackgroundImage: 'bg1.png',
            }
        },
        {
            name: 'quotes_calendar',
            title: 'Quotes Calendar',
            img: '/images/quotes-calendar-thumb.png',
            path: '/template/quotes_calendar',
            premium: false,
            settings: {}
        },
    ]);
    const templateIndex = ref(0);
    const freeTemplates = computed(() => {
        return templates.value.filter(i => i.premium === false);
    });
    const premiumTemplates = computed(() => {
        return templates.value.filter(i => i.premium === true);
    });
    // background-image: v-bind('url("'+'/images/backgrounds/'+calendarTemplatesStore.templates[2].settings.headerBackgroundImage);
    function getBgUrl(filename) {
        return 'url("/images/backgrounds/' + filename + '")';
    }
    function getTemplateIndex(templateName) {
        return templates.value.findIndex(obj => {
            return obj.name === templateName;
        });
    }
    return { templates, templateIndex, freeTemplates, premiumTemplates, getBgUrl, getTemplateIndex };
});
