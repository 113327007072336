import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PhotoEditor from '../views/PhotoEditor.vue';
import BasicTemplate from '../calendar-templates/BasicTemplate.vue';
import WatercolorTemplate from '../calendar-templates/WatercolorTemplate.vue';
import PhotoTemplate from '../calendar-templates/PhotoTemplate.vue';
import QuotesTemplate from '../calendar-templates/QuotesTemplate.vue';
import PremiumTemplate from '../calendar-templates/PremiumTemplate.vue';
import BuyDialog from '../views/BuyDialog.vue';
import DownloadDialog from '../views/DownloadDialog.vue';
const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        children: [
            {
                path: '',
                name: 'basic_template',
                component: BasicTemplate,
            },
            {
                path: '/template/watercolor',
                name: 'watercolor_template',
                component: WatercolorTemplate,
            },
            {
                path: '/template/photo_calendar',
                name: 'premium_photo',
                component: PhotoTemplate,
            },
            {
                path: '/template/premium_template',
                name: 'premium_photo2',
                component: PremiumTemplate,
            },
            {
                path: '/template/quotes_calendar',
                name: 'quotes_calendar',
                component: QuotesTemplate,
            }
        ],
    },
    {
        path: '/photo/:id',
        name: 'photoEditor',
        component: PhotoEditor,
    },
    {
        path: '/make-free',
        name: 'makeFree',
        component: DownloadDialog,
    },
    {
        path: '/make-premium',
        name: 'makePremium',
        component: BuyDialog,
    }
    /*
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('../views/SubscribeEmail.vue'),
      props: true,
    }
    */
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;
