import { defineStore } from 'pinia';
import { ref } from 'vue';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import dayGridPlugin from '@fullcalendar/daygrid';
export const useCalendarSettingsStore = defineStore('calendarSettings', () => {
    const calendarDate = ref(null);
    const calendarOptions = ref({
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        datesSet: event => {
            calendarDate.value = event.view.currentStart.toISOString();
            //calendarDate.value = new Date(event.view.currentStart); esto realentiza la aplicación bastante
            if (document.querySelectorAll('tbody[role="presentation"] tr').length > 5) {
                document.querySelector('tbody[role="presentation"]')?.classList.add('sixrows');
                //document.querySelector('#calendar1 > div.fc-view-container > div > table > tbody > tr > td > div')?.classList.add('sixrows');
            }
            else {
                document.querySelector('tbody[role="presentation"]')?.classList.remove('sixrows');
                //document.querySelector('#calendar1 > div.fc-view-container > div > table > tbody > tr > td > div')?.classList.remove('sixrows');
            }
        },
        timeZone: 'UTC',
        aspectRatio: 1.42,
        headerToolbar: {
            start: '',
            center: 'title',
            end: 'prev,next'
        },
        firstDay: 0,
        fixedWeekCount: false,
        dayHeaderFormat: {
            weekday: 'short',
        },
        locale: 'en',
        titleFormat: { year: 'numeric', month: 'long' },
        initialDate: calendarDate,
    });
    const languages = [
        { locale: 'en', name: 'English' },
        { locale: 'es', name: 'Español' },
        { locale: 'fr', name: 'Français' },
        { locale: 'pt', name: 'Portugues' },
        { locale: 'de', name: 'Deutsch' },
        { locale: 'it', name: 'Italiano' },
        { locale: 'pl', name: 'Polish' },
        { locale: 'nl', name: 'Dutch' },
        { locale: 'sw', name: 'Swedish' },
    ];
    const quotes = ref([
        { theme: 'January', quote: 'January is the month for dreaming and planning.' },
        { theme: 'February', quote: 'February is the border between winter and spring.' },
        { theme: 'March', quote: 'March brings breezes loud and shrill, to stir the dancing daffodil.' },
        { theme: 'April', quote: 'April showers bring May flowers.' },
        { theme: 'May', quote: 'May is the month of fresh beginnings.' },
        { theme: 'June', quote: 'June is the gateway to summer.' },
        { theme: 'July', quote: 'July is a blind date with summer.' },
        { theme: 'August', quote: 'August is the Sunday of summer.' },
        { theme: 'September', quote: 'September tries its best to have us forget summer.' },
        { theme: 'October', quote: 'October is a symphony of permanence and change.' },
        { theme: 'November', quote: 'November comes with winds and rain.' },
        { theme: 'December', quote: 'December’s wintery breath is already clouding the pond.' }
    ]);
    const quote = {
        text: ref(null),
        image: ref(null),
        fontSize: ref(38),
        color: ref('#ffffff'),
        fontFace: ref({ fontName: 'Allura', cssClass: 'allura' }),
        shadowColor: ref('transparent'),
    };
    const title = {
        fontSize: ref(24),
        color: ref('#000000'),
        fontFace: ref({ fontName: 'Nunito', cssClass: 'nunito' }),
        shadowColor: ref('transparent'),
    };
    const calendarCanvas = ref(null);
    const imagesByMonth = {
        "1": [
            "decbg1.jpg",
            "decbg10.jpg",
            "decbg11.jpg",
            "decbg14.jpg",
            "decbg16.jpg",
            "decbg17.jpg",
            "decbg18.jpg",
            "decbg2.jpg",
            "decbg20.jpg",
            "decbg21.jpg",
            "decbg22.jpg",
            "decbg23.jpg",
            "decbg3.jpg",
            "decbg31.jpg",
            "decbg32.jpg",
            "decbg33.jpg",
            "decbg34.jpg",
            "decbg36.jpg",
            "decbg37.jpg",
            "decbg38.jpg",
            "decbg39.jpg",
            "decbg4.jpg",
            "decbg40.jpg",
            "decbg41.jpg",
            "decbg42.jpg",
            "decbg5.jpg",
            "decbg6.jpg",
            "decbg8.jpg",
            "decbg9.jpg"
        ],
        "2": [
            "decbg1.jpg",
            "decbg10.jpg",
            "decbg11.jpg",
            "decbg14.jpg",
            "decbg16.jpg",
            "decbg17.jpg",
            "decbg18.jpg",
            "decbg2.jpg",
            "decbg20.jpg",
            "decbg21.jpg",
            "decbg22.jpg",
            "decbg23.jpg",
            "decbg3.jpg",
            "decbg31.jpg",
            "decbg32.jpg",
            "decbg33.jpg",
            "decbg34.jpg",
            "decbg36.jpg",
            "decbg37.jpg",
            "decbg38.jpg",
            "decbg39.jpg",
            "decbg4.jpg",
            "decbg40.jpg",
            "decbg41.jpg",
            "decbg42.jpg",
            "decbg5.jpg",
            "decbg6.jpg",
            "decbg8.jpg",
            "decbg9.jpg"
        ],
        "3": [
            "pexels-8moments-953241.jpg",
            "pexels-alena-koval-233944-920154.jpg",
            "pexels-anne-sophie-parent-1111377-2104881.jpg",
            "pexels-asphotograpy-94847.jpg",
            "pexels-florian-o-1587793709-28949900.jpg",
            "pexels-jill-wellington-1638660-413707.jpg",
            "pexels-magda-ehlers-pexels-985266.jpg",
            "pexels-nietjuh-612807.jpg",
            "pexels-pixabay-159406.jpg",
            "pexels-pixabay-355663.jpg",
            "pexels-pixabay-40731.jpg",
            "pexels-pixabay-414083.jpg",
            "pexels-pixabay-414181.jpg",
            "pexels-pixabay-414660.jpg",
            "pexels-pixabay-67857.jpg",
            "pexels-pixabay-69776.jpg",
            "pexels-ramuware-102896.jpg",
            "pexels-suju-2099737.jpg",
            "pexels-vittoriostaffolani-391726.jpg"
        ],
        "4": [
            "pexels-8moments-953241.jpg",
            "pexels-alena-koval-233944-920154.jpg",
            "pexels-anne-sophie-parent-1111377-2104881.jpg",
            "pexels-asphotograpy-94847.jpg",
            "pexels-florian-o-1587793709-28949900.jpg",
            "pexels-jill-wellington-1638660-413707.jpg",
            "pexels-magda-ehlers-pexels-985266.jpg",
            "pexels-nietjuh-612807.jpg",
            "pexels-pixabay-159406.jpg",
            "pexels-pixabay-355663.jpg",
            "pexels-pixabay-40731.jpg",
            "pexels-pixabay-414083.jpg",
            "pexels-pixabay-414181.jpg",
            "pexels-pixabay-414660.jpg",
            "pexels-pixabay-67857.jpg",
            "pexels-pixabay-69776.jpg",
            "pexels-ramuware-102896.jpg",
            "pexels-suju-2099737.jpg",
            "pexels-vittoriostaffolani-391726.jpg"
        ],
        "5": [
            "pexels-8moments-953241.jpg",
            "pexels-alena-koval-233944-920154.jpg",
            "pexels-anne-sophie-parent-1111377-2104881.jpg",
            "pexels-asphotograpy-94847.jpg",
            "pexels-florian-o-1587793709-28949900.jpg",
            "pexels-jill-wellington-1638660-413707.jpg",
            "pexels-magda-ehlers-pexels-985266.jpg",
            "pexels-nietjuh-612807.jpg",
            "pexels-pixabay-159406.jpg",
            "pexels-pixabay-355663.jpg",
            "pexels-pixabay-40731.jpg",
            "pexels-pixabay-414083.jpg",
            "pexels-pixabay-414181.jpg",
            "pexels-pixabay-414660.jpg",
            "pexels-pixabay-67857.jpg",
            "pexels-pixabay-69776.jpg",
            "pexels-ramuware-102896.jpg",
            "pexels-suju-2099737.jpg",
            "pexels-vittoriostaffolani-391726.jpg"
        ],
        "6": [
            "pexels-andre-furtado-43594-1263986.jpg",
            "pexels-apasaric-1285625.jpg",
            "pexels-arin-turkay-39583-450038.jpg",
            "pexels-asadphoto-1450353.jpg",
            "pexels-fecundap6-358904.jpg",
            "pexels-grizzlybear-421759.jpg",
            "pexels-jean-frenna-84337-1045113.jpg",
            "pexels-marctutorials-298692-1152359.jpg",
            "pexels-maribernotti-1049298.jpg",
            "pexels-mastercowley-1089168.jpg",
            "pexels-nietjuh-1209611.jpg",
            "pexels-nubikini-386025.jpg",
            "pexels-palocech-286763.jpg",
            "pexels-perfecto-capucine-515848-1247526.jpg",
            "pexels-peterfazekas-1170572.jpg",
            "pexels-peterfazekas-1170594.jpg",
            "pexels-pixabay-158756.jpg",
            "pexels-pixabay-261403.jpg",
            "pexels-pixabay-262325.jpg",
            "pexels-pixabay-33044.jpg",
            "pexels-pixabay-413960.jpg",
            "pexels-pixabay-531035.jpg",
            "pexels-pok-rie-33563-268261.jpg",
            "pexels-psco-137132.jpg",
            "pexels-zhangkaiyv-189848.jpg"
        ],
        "7": [
            "pexels-andre-furtado-43594-1263986.jpg",
            "pexels-apasaric-1285625.jpg",
            "pexels-arin-turkay-39583-450038.jpg",
            "pexels-asadphoto-1450353.jpg",
            "pexels-fecundap6-358904.jpg",
            "pexels-grizzlybear-421759.jpg",
            "pexels-jean-frenna-84337-1045113.jpg",
            "pexels-marctutorials-298692-1152359.jpg",
            "pexels-maribernotti-1049298.jpg",
            "pexels-mastercowley-1089168.jpg",
            "pexels-nietjuh-1209611.jpg",
            "pexels-nubikini-386025.jpg",
            "pexels-palocech-286763.jpg",
            "pexels-perfecto-capucine-515848-1247526.jpg",
            "pexels-peterfazekas-1170572.jpg",
            "pexels-peterfazekas-1170594.jpg",
            "pexels-pixabay-158756.jpg",
            "pexels-pixabay-261403.jpg",
            "pexels-pixabay-262325.jpg",
            "pexels-pixabay-33044.jpg",
            "pexels-pixabay-413960.jpg",
            "pexels-pixabay-531035.jpg",
            "pexels-pok-rie-33563-268261.jpg",
            "pexels-psco-137132.jpg",
            "pexels-zhangkaiyv-189848.jpg"
        ],
        "8": [
            "pexels-andre-furtado-43594-1263986.jpg",
            "pexels-apasaric-1285625.jpg",
            "pexels-arin-turkay-39583-450038.jpg",
            "pexels-asadphoto-1450353.jpg",
            "pexels-fecundap6-358904.jpg",
            "pexels-grizzlybear-421759.jpg",
            "pexels-jean-frenna-84337-1045113.jpg",
            "pexels-marctutorials-298692-1152359.jpg",
            "pexels-maribernotti-1049298.jpg",
            "pexels-mastercowley-1089168.jpg",
            "pexels-nietjuh-1209611.jpg",
            "pexels-nubikini-386025.jpg",
            "pexels-palocech-286763.jpg",
            "pexels-perfecto-capucine-515848-1247526.jpg",
            "pexels-peterfazekas-1170572.jpg",
            "pexels-peterfazekas-1170594.jpg",
            "pexels-pixabay-158756.jpg",
            "pexels-pixabay-261403.jpg",
            "pexels-pixabay-262325.jpg",
            "pexels-pixabay-33044.jpg",
            "pexels-pixabay-413960.jpg",
            "pexels-pixabay-531035.jpg",
            "pexels-pok-rie-33563-268261.jpg",
            "pexels-psco-137132.jpg",
            "pexels-zhangkaiyv-189848.jpg"
        ],
        "9": [
            "sepbg1.jpg",
            "sepbg10.jpg",
            "sepbg11.jpg",
            "sepbg12.jpg",
            "sepbg13.jpg",
            "sepbg14.jpg",
            "sepbg15.jpg",
            "sepbg16.jpg",
            "sepbg17.jpg",
            "sepbg18.jpg",
            "sepbg19.jpg",
            "sepbg2.jpg",
            "sepbg20.jpg",
            "sepbg21.jpg",
            "sepbg22.jpg",
            "sepbg23.jpg",
            "sepbg24.jpg",
            "sepbg25.jpg",
            "sepbg26.jpg",
            "sepbg27.jpg",
            "sepbg28.jpg",
            "sepbg29.jpg",
            "sepbg3.jpg",
            "sepbg30.jpg",
            "sepbg31.jpg",
            "sepbg4.jpg",
            "sepbg5.jpg",
            "sepbg6.jpg",
            "sepbg7.jpg",
            "sepbg8.jpg",
            "sepbg9.jpg"
        ],
        "10": [
            "pexels-aleksandar-cvetanovic-605352-1480861.jpg",
            "pexels-ifreestock-619417.jpg",
            "pexels-ifreestock-619420.jpg",
            "pexels-ifreestock-619424.jpg",
            "pexels-timmossholder-3017107.jpg",
            "sepbg1.jpg",
            "sepbg10.jpg",
            "sepbg11.jpg",
            "sepbg12.jpg",
            "sepbg13.jpg",
            "sepbg14.jpg",
            "sepbg15.jpg",
            "sepbg16.jpg",
            "sepbg17.jpg",
            "sepbg18.jpg",
            "sepbg19.jpg",
            "sepbg2.jpg",
            "sepbg21.jpg",
            "sepbg22.jpg",
            "sepbg23.jpg",
            "sepbg24.jpg",
            "sepbg25.jpg",
            "sepbg26.jpg",
            "sepbg27.jpg",
            "sepbg28.jpg",
            "sepbg29.jpg",
            "sepbg3.jpg",
            "sepbg30.jpg",
            "sepbg31.jpg",
            "sepbg4.jpg",
            "sepbg5.jpg",
            "sepbg6.jpg",
            "sepbg7.jpg",
            "sepbg8.jpg",
            "sepbg9.jpg"
        ],
        "11": [
            "pexels-brett-sayles-1550135.jpg",
            "pexels-karolina-grabowska-5429785.jpg",
            "pexels-kelly-1179532-3235470.jpg",
            "pexels-niki-clark-9995029-14413940.jpg",
            "pexels-nubikini-385976.jpg",
            "pexels-vlada-karpovich-9969187.jpg",
            "sepbg1.jpg",
            "sepbg10.jpg",
            "sepbg11.jpg",
            "sepbg12.jpg",
            "sepbg13.jpg",
            "sepbg14.jpg",
            "sepbg15.jpg",
            "sepbg16.jpg",
            "sepbg17.jpg",
            "sepbg18.jpg",
            "sepbg19.jpg",
            "sepbg2.jpg",
            "sepbg21.jpg",
            "sepbg22.jpg",
            "sepbg23.jpg",
            "sepbg24.jpg",
            "sepbg25.jpg",
            "sepbg26.jpg",
            "sepbg27.jpg",
            "sepbg28.jpg",
            "sepbg29.jpg",
            "sepbg3.jpg",
            "sepbg30.jpg",
            "sepbg31.jpg",
            "sepbg4.jpg",
            "sepbg5.jpg",
            "sepbg6.jpg",
            "sepbg7.jpg",
            "sepbg8.jpg",
            "sepbg9.jpg"
        ],
        "12": [
            "decbg1.jpg",
            "decbg10.jpg",
            "decbg11.jpg",
            "decbg12.jpg",
            "decbg13.jpg",
            "decbg14.jpg",
            "decbg15.jpg",
            "decbg16.jpg",
            "decbg17.jpg",
            "decbg18.jpg",
            "decbg19.jpg",
            "decbg2.jpg",
            "decbg20.jpg",
            "decbg21.jpg",
            "decbg22.jpg",
            "decbg23.jpg",
            "decbg24.jpg",
            "decbg25.jpg",
            "decbg26.jpg",
            "decbg27.jpg",
            "decbg28.jpg",
            "decbg29.jpg",
            "decbg3.jpg",
            "decbg30.jpg",
            "decbg31.jpg",
            "decbg32.jpg",
            "decbg33.jpg",
            "decbg34.jpg",
            "decbg35.jpg",
            "decbg36.jpg",
            "decbg37.jpg",
            "decbg38.jpg",
            "decbg39.jpg",
            "decbg4.jpg",
            "decbg40.jpg",
            "decbg41.jpg",
            "decbg42.jpg",
            "decbg5.jpg",
            "decbg6.jpg",
            "decbg7.jpg",
            "decbg8.jpg",
            "decbg9.jpg"
        ]
    };
    function setFirstDay(i) {
        calendarOptions.value.firstDay = i;
    }
    function setDayNames(format) {
        calendarOptions.value.dayHeaderFormat = { weekday: format };
    }
    function setLanguage(lang) {
        calendarOptions.value.locale = lang;
    }
    function saveImage(imagePath, imageName) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.setAttribute('download', imageName + '.png');
        link.setAttribute('href', imagePath.replace("image/png", "image/octet-stream"));
        link.click();
    }
    function updateQuoteBackground() {
        const date = new Date(calendarDate.value);
        if (date) {
            const month = date.getMonth() + 1;
            const images = imagesByMonth[month];
            quote.image.value = images[Math.floor(Math.random() * images.length)];
        }
    }
    return { calendarDate, calendarOptions, languages, title, calendarCanvas, quote, quotes, setFirstDay, setDayNames, setLanguage, saveImage, updateQuoteBackground };
});
