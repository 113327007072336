import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './ccm/router';
import CCM from './ccm.vue';
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
const pinia = createPinia();
const app = createApp(CCM);
app.use(pinia);
app.use(router);
//app.use(BootstrapVue3)
app.mount('#app');
